import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import "./App.css";
import Home from "./Containers/Home/Home";
import Intro from "./Containers/Intro/Intro";
import Login from "./Containers/Login/Login";
import NewPassword from "./Containers/NewPassword/NewPassword";
import Notice from "./Containers/Notice/Notice";
import PasswordRecovery from "./Containers/PasswordRecovery/PasswordRecovery";
import Plan from "./Containers/Plan/Plan";
import Preparation from "./Containers/Preparation/Preparation";
import Questionnaire from "./Containers/Questionnaire/Questionnaire";
import Questionnaire2 from "./Containers/Questionnaire/Questionnaire2";
import RecoveryCode from "./Containers/RecoveryCode/RecoveryCode";
import ReviewAndSelect from "./Containers/Review/ReviewAndSelect";
import Summary from "./Containers/Summary/Summary";
import WhatsImportant from "./Containers/WhatsImportant/WhatsImportant";
import AdminRoute from "./components/PrivateRoutesHOC/AdminRoute";
import PrivateRoute from "./components/PrivateRoutesHOC/PrivateRoute";
import ResetRoute from "./components/PrivateRoutesHOC/ResetRoute";
import TreatmentDetails from "./components/Review/TreatmentDetails";
// import Dashboard from './Containers/Admin/Dashboard/Dashboard';
import Categories from "./Containers/Admin/Categories/Categories";
import FactorsList from "./Containers/Admin/Factors/FactorsList";
import MedicationsClassifications from "./Containers/Admin/Medications/Classifications";
import MedicationsList from "./Containers/Admin/Medications/MedicationsList";
import SuggestionsList from "./Containers/Admin/Suggestions/SuggestionsList";
import TreatmentClassifications from "./Containers/Admin/Treatments/Classifications";
import Studies from "./Containers/Admin/Treatments/Studies";
import studyResults from "./Containers/Admin/Treatments/StudyResults";
import TreatmentsList from "./Containers/Admin/Treatments/TreatmentsList";
import UsersList from "./Containers/Admin/Users/UsersList";
import WhatsImportantList from "./Containers/Admin/WhatsImportant/WhatsImportantList";
import NotFoundPage from "./components/PageNotFound/PageNotFound";

import PainAvg from "./Containers/UserDash/PainAvg/PainAvg";
import Plots from "./Containers/UserDash/Plots/Plots";
//import PastSum from './Containers/UserDash/PastSum/PastSum';
import VerifyLinks from "./Containers/Admin/VerifyLinks/VerifyLinks";
import TreatmentAvg from "./Containers/UserDash/TreatmentAvg/TreatmentAvg";
import TipDetails from "./components/Review/TipDetails";

const ScrollToTop = () => {
  window.scrollTo(0, 0);
  return null;
};

const App = () => {
  return (
    <BrowserRouter basename="/">
      <div className="App">
        <Route component={ScrollToTop} />
        <Switch>
          <Route exact path="/" component={Login}></Route>
          {/* Serve new password page after verifying token */}
          <ResetRoute exact path="/new-password" component={NewPassword} />
          <Route exact path="/reset" component={PasswordRecovery}></Route>
          <Route
            exact
            path="/reset-email-sent"
            component={RecoveryCode}
          ></Route>
          <PrivateRoute exact path="/home" component={Home} />
          <PrivateRoute exact path="/preparation" component={Preparation} />
          <PrivateRoute exact path="/notice" component={Notice} />
          <PrivateRoute exact path="/intro" component={Intro} />
          <PrivateRoute exact path="/questionnaire" component={Questionnaire} />
          <PrivateRoute
            exact
            path="/questionnaire2"
            component={Questionnaire2}
          />
          <PrivateRoute
            exact
            path="/whats-important-to-you"
            component={WhatsImportant}
          />
          <Route
            path="/treatment-details/:treatment"
            render={(c) => <TreatmentDetails data={c} />}
          />
          <Route
            path="/tip-details/:tip"
            render={(c) => <TipDetails data={c} />}
          />
          <Redirect
            exact
            from="/review-and-select"
            to="/review-and-select/recommendations"
          />
          <PrivateRoute
            path="/review-and-select/:tab"
            component={ReviewAndSelect}
          />
          <PrivateRoute exact path="/make-your-plan" component={Plan} />
          <PrivateRoute exact path="/summary" component={Summary} />

          <Redirect exact from="/adminPanel" to="/adminPanel/users" />
          {/* <AdminRoute exact path="/adminPanel/dashboard" component={Dashboard}/> */}
          <AdminRoute exact path="/adminPanel/users" component={UsersList} />
          <AdminRoute
            exact
            path="/adminPanel/treatments"
            component={TreatmentsList}
          />
          <AdminRoute exact path="/adminPanel/studies" component={Studies} />
          <AdminRoute
            exact
            path="/adminPanel/studyResults"
            component={studyResults}
          />
          <AdminRoute
            exact
            path="/adminPanel/treatmentClassifications"
            component={TreatmentClassifications}
          />
          <AdminRoute
            exact
            path="/adminPanel/medications"
            component={MedicationsList}
          />
          <AdminRoute
            exact
            path="/adminPanel/medicationClassifications"
            component={MedicationsClassifications}
          />
          <AdminRoute
            exact
            path="/adminPanel/whatsimportant"
            component={WhatsImportantList}
          />
          <AdminRoute
            exact
            path="/adminPanel/factors"
            component={FactorsList}
          />
          <AdminRoute
            exact
            path="/adminPanel/suggestions"
            component={SuggestionsList}
          />
          <AdminRoute
            exact
            path="/adminPanel/categories"
            component={Categories}
          />
          {/* <AdminRoute exact path="/adminPanel/symptoms" component={SymptomsList}/> */}
          <AdminRoute
            exact
            path="/adminPanel/verifyLinks"
            component={VerifyLinks}
          />

          <Redirect exact from="/userPanel" to="/userPanel/average" />
          <PrivateRoute exact path="/userPanel/average" component={PainAvg} />
          <PrivateRoute exact path="/userPanel/manage" component={Plots} />
          <PrivateRoute
            exact
            path="/userPanel/treatments"
            component={TreatmentAvg}
          />
          {/*  <PrivateRoute exact path="/userPanel/summary" component={PastSum}/> */}

          <Route path="" component={NotFoundPage} />

          {/* <Redirect to="" /> */}
        </Switch>
      </div>
    </BrowserRouter>
  );
};

export default App;