/**
 * @file
 * container for step 1 "Pain & treatments"
 * display content and handles storing data to the database
 * @author <akel.ms93@gmail.com>
 */

import React, { useEffect, useState } from "react";
import { useBeforeunload } from "react-beforeunload";
import { withRouter } from "react-router-dom";
import Header from "../../components/Home/Header";
import QStepTwo from "../../components/Questionnaire/QstepTwo";
import QSymptom from "../../components/Questionnaire/SymptomQuestions";
import Subheader from "../../components/UI/Subheader/Subheader";

import Footer from "../../components/Footer";

import { getRequest, postRequest } from "../../API/ApiHandler";
import { handleRecommendations } from "../../components/GenerateRecommendations";
import { updateLogStepOne } from "../../components/HandleUserLog";
import FailedSaveDialog from "../../components/UI/FailedSaveDialog";
import NavigationDialog from "../../components/UI/NavigationDialog";
import SaveButton from "../../components/UI/SaveButton";

import { getUserType, logout } from "../../components/AuthenticationService";

import { NavLink as RRNav } from "react-router-dom";
import { Button } from "reactstrap";
import IdleComponent from "../../components/IdleComponent";
import "./Questionnaire.css";

const Questionnaire = (props) => {
  // Q2 pain areas values
  const [painAreas, setPainAreas] = useState();

  // Q3 selected meds
  const [selectedMeds, setSelectedMeds] = useState([]);
  const [prescribedText, setPrescribedText] = useState("");
  // Q4 other selected meds and treatments
  const [selectedTreatments, setSelectedTreatments] = useState([]);
  const [treatmentText, setTreatmentText] = useState("");
  // Q5 how often do you use your treatments values
  const [howOften, setHowOften] = useState({});
  const [frHowOften, setFrHowOften] = useState({});
  // Q6 how well does your treatment
  const [controlArthritis, setControlArthritis] = useState(0);
  const [managePain, setManagePain] = useState(0);

  const [symptoms, setSymptoms] = useState({});

  const [saved, setSaved] = useState(true);
  const [displayNavDialog, setDisplayNavDialog] = useState(false);
  const [navBy, setNavBy] = useState("");
  const [navTo, setNavTo] = useState("");
  const [didSelect, setDidSelect] = useState(false);

  const [saveStatus, setSaveStatus] = useState("default");
  const [open, setOpen] = useState(false);

  useBeforeunload((event) => {
    if (!saved) event.preventDefault();
  });

  React.useEffect(() => {
    getUserType();
  });

  const symptomFields = {
    selected: false,
    level: 1
  };

  const getSymptoms = async () => {
    try {
      const { data } = await getRequest(`/symptoms`);

      const symptomData = {};

      for (const symptom of data) {
        symptomData[symptom.name.toLowerCase()] = {
          ...symptomFields,
          fr_name: symptom.fr_name.toLowerCase(),
          question: symptom.question,
          fr_question: symptom.fr_question
        };
      }

      setSymptoms(symptomData);
    } catch (error) {
      console.error("Error fetching symptoms:", error);
    }
  };

  const setSavedQuestions = async () => {
    const sessionModifiedSymptoms =
      JSON.parse(sessionStorage.getItem("selectedSymptoms")) || [];

    if (sessionModifiedSymptoms.length > 0) {
      const promises = sessionModifiedSymptoms.map(async (symptomName) => {
        try {
          const symptom = symptomName.toLowerCase();
          const response = await getRequest(
            `/symptomQuestions/${symptom}/user`
          );

          if (response && response.data) {
            const { data } = response;
            updateSymptom(symptom, "level", data.symptom_level || 1);

            if (symptom === "pain") {
              const items = await getRequest(`/painAreas/user`);
              if (items && items.data) {
                const selectedAreas = [];
                for (let part in items.data) {
                  if (items.data[part] === true) selectedAreas.push(part);
                }
                setPainAreas(selectedAreas);
              }
            }
          } else {
            console.log(
              `No data found for symptom: ${symptom}. Setting defaults.`
            );
            updateSymptom(symptom, "level", 1);
          }
        } catch (err) {
          console.log(
            `Error fetching data for symptom: ${symptomName.toLowerCase()}`,
            err
          );
          updateSymptom(symptomName.toLowerCase(), "level", 1);
        }
      });

      try {
        await Promise.all(promises);
        setSaved(true);
      } catch (error) {
        console.log("Error processing saved questions:", error);
      }
    }
  };

  const saveHandler = async () => {
    try {
      const results = await Promise.all([
        savePainAreas(),
        saveSelectedMeds(),
        savePrescribedText(),
        saveAllQuestions(),
        saveSelectedTreatments(),
        saveTreatmentText()
      ]);

      const failed = results.some(
        (result) => result === undefined || result.error
      );

      if (!failed) {
        await handleRecommendations();
        updateLog();
        setSaved(true);
        setSaveStatus("success");
      } else {
        setSaveStatus("default");
        setOpen(true);
      }
    } catch (error) {
      console.error("Save failed: ", error);
      setSaveStatus("default");
      setOpen(true);
    }
  };

  const saveQuestionProgress = (level, path) => {
    const input = {
      symptom_level: level
    };
    return postRequest(path, input, setSaveStatus);
  };

  const saveAllQuestions = () => {
    const questionPromises = [];
    for (const symptom in symptoms) {
      if (symptoms[symptom].selected) {
        const promise = saveQuestionProgress(
          symptoms[symptom].level,
          `/symptomQuestions/${symptom}`
        );
        questionPromises.push(promise);
      }
    }

    return Promise.all(questionPromises);
  };

  const updateSymptom = (symptom, field, value) => {
    setSymptoms((prevSymptoms) => ({
      ...prevSymptoms,
      [symptom]: {
        ...prevSymptoms[symptom],
        [field]: value
      }
    }));
  };

  const updateLog = () => {
    // List of prescribed meds
    const presMeds = selectedMeds
      .filter((med) => med.classification.section === "prescribed")
      .map((med) => {
        let medName = { name: med.name };
        return medName;
      });

    // list of other meds and treatments
    let otherTreats = selectedMeds
      .filter((med) => med.classification.section === "other")
      .map((med) => {
        let medName = { name: med.name };
        return medName;
      });

    let treats = selectedTreatments.map((el) => {
      let names = { name: el.name, fr_name: el.fr_name };
      return names;
    });

    otherTreats = [...otherTreats, ...treats];

    const selectedAreas = [];
    for (let area in painAreas) {
      if (painAreas[area]) selectedAreas.push({ area });
    }
    const data = {
      stiffness_level: symptoms["stiffness"].level,
      stress_level: symptoms["stress"].level,
      anxiety_level: symptoms["anxiety"].level,
      fatigue_level: symptoms["fatigue"].level,
      pain_level: symptoms["pain"].level,
      pain_areas: selectedAreas,
      selected_meds: presMeds,
      prescribed_text: prescribedText,
      other_treatments: otherTreats,
      treatment_text: treatmentText,
      howOften_pres: howOften.pres,
      howOften_other: howOften.other,
      control_arthritis: controlArthritis,
      manage_pain: managePain
    };
    updateLogStepOne(data);
  };

  const savePainAreas = () => {
    const input = { pain_areas: painAreas };
    return postRequest("/painAreas", input, setSaveStatus);
  };

  const saveSelectedMeds = () => {
    const input = { selectedMeds: selectedMeds };
    return postRequest("/userMedications", input, setSaveStatus);
  };

  const savePrescribedText = () => {
    const input = { text: prescribedText };
    return postRequest("/prescribedText", input, setSaveStatus);
  };

  const saveSelectedTreatments = () => {
    const input = { selectedTreatments: selectedTreatments };
    return postRequest("/userTreatments", input, setSaveStatus);
  };

  const saveTreatmentText = () => {
    const input = { text: treatmentText };
    return postRequest("/treatmentText", input, setSaveStatus);
  };

  const getUserSymptoms = async () => {
    const sessionModifiedSymptoms =
      JSON.parse(sessionStorage.getItem("selectedSymptoms")) || [];

    for (const symptom of sessionModifiedSymptoms) {
      updateSymptom(symptom.toLowerCase(), "selected", true);
    }
  };

  const handleButtonNav = () => {
    if (saved) {
      props.history.push(`/questionnaire2`);
    } else {
      setNavBy("button");
      setDisplayNavDialog(true);
    }
  };

  const handleStepperNav = (to) => {
    if (saved) {
      props.history.push(to);
    } else {
      setNavBy("stepper");
      setDisplayNavDialog(true);
    }
  };

  const handleSubHeaderAdminNav = () => {
    if (saved) {
      props.history.push(`/adminPanel/users`);
    } else {
      setNavBy("admin");
      setDisplayNavDialog(true);
    }
  };

  const handleSubHeaderProgressNav = () => {
    if (saved) {
      props.history.push(`/userPanel/average`);
    } else {
      setNavBy("user");
      setDisplayNavDialog(true);
    }
  };

  const handleSubHeaderLogout = () => {
    if (saved) {
      logout();
      props.history.push(`/`);
    } else {
      setNavBy("logout");
      setDisplayNavDialog(true);
    }
  };

  const closeNavDialog = (selection) => {
    selection === 0
      ? setDisplayNavDialog(false)
      : navBy === "button"
      ? props.history.push(`/whats-important-to-you`)
      : navBy === "admin"
      ? props.history.push(`/adminPanel/users`)
      : navBy === "user"
      ? props.history.push(`/userPanel/average`)
      : navBy === "logout"
      ? props.history.push(`/`)
      : props.history.push(navTo);
  };

  useEffect(() => {
    if (localStorage.getItem("s1Trial")) setDidSelect(true);
  });

  useEffect(() => {
    const initializePage = async () => {
      await getSymptoms();
      await getUserSymptoms();
    };
    initializePage();
    setSavedQuestions();
  }, []);

  let lang = localStorage.getItem("language");

  const renderSymptoms = () => {
    const components = [];
    for (const symptomType in symptoms) {
      if (!symptoms[symptomType].selected) {
        continue;
      }
      if (symptomType === "pain") {
        components.push(
          <React.Fragment key={symptomType}>
            <QSymptom
              symptomType={symptomType}
              frSymptomType={symptoms[symptomType].fr_name}
              headingQuestion={symptoms[symptomType].question}
              frHeadingQuestion={symptoms[symptomType].fr_question}
              questionNo={1}
              setSymptomLevel={(level) =>
                updateSymptom(symptomType, "level", level)
              }
              symptomLevel={symptoms[symptomType].level}
              setSaved={setSaved}
              setSaveStatus={setSaveStatus}
            />
            <QStepTwo
              questionNo={2}
              setPainAreas={setPainAreas}
              setSaved={setSaved}
              setSaveStatus={setSaveStatus}
            />
          </React.Fragment>
        );
      } else {
        components.push(
          <QSymptom
            key={symptomType}
            symptomType={symptomType}
            frSymptomType={symptoms[symptomType].fr_name}
            headingQuestion={symptoms[symptomType].question}
            frHeadingQuestion={symptoms[symptomType].fr_question}
            questionNo={1}
            setSymptomLevel={(level) =>
              updateSymptom(symptomType, "level", level)
            }
            symptomLevel={symptoms[symptomType].level}
            setSaved={setSaved}
            setSaveStatus={setSaveStatus}
          />
        );
      }
    }

    return components.length > 0 ? <>{components}</> : null;
  };

  return (
    <div className="wrapper">
      <IdleComponent
        history={props.history}
        saveHandler={saveHandler}
        status={saveStatus}
      />
      <div className="page-header">
        <Header
          current={props}
          handleNav={handleStepperNav}
          setNavTo={setNavTo}
        />
      </div>
      <div className="page-subheader">
        <Subheader
          title={
            lang === "English"
              ? "Symptoms & treatments"
              : "Symptômes et traitements"
          }
          handleAdmin={handleSubHeaderAdminNav}
          handleUser={handleSubHeaderProgressNav}
          handleLogout={handleSubHeaderLogout}
        />
      </div>

      <div className="body-container">
        <div
          style={{
            display: "flex",
            marginTop: "auto",
            padding: ".5rem",
            alignItems: "center"
          }}
        >
          <Button className="next-btn" tag={RRNav} to="/preparation">
            {lang === "English" ? "Back" : "Retour"}
          </Button>
          <SaveButton
            saveHandler={saveHandler}
            saveStatus={saveStatus}
            loc={"tp"}
          />
          <Button
            className="next-btn"
            tag={RRNav}
            to="/questionnaire2"
            onClick={() => {
              if (!saved) {
                saveHandler();
              }
            }}
          >
            {lang === "English" ? "Next" : "Suivant(e)"}
          </Button>
        </div>

        {renderSymptoms()}
        <div
          style={{
            display: "flex",
            marginTop: "auto",
            padding: ".5rem",
            alignItems: "center"
          }}
        >
          <Button className="next-btn" tag={RRNav} to="/preparation">
            {lang === "English" ? "Back" : "Retour"}
          </Button>
          <SaveButton
            saveHandler={saveHandler}
            saveStatus={saveStatus}
            loc={"tp"}
          />
          <Button
            className="next-btn"
            tag={RRNav}
            to="/questionnaire2"
            onClick={() => {
              if (!saved) {
                saveHandler();
              }
            }}
          >
            {lang === "English" ? "Next" : "Suivant(e)"}
          </Button>
        </div>
      </div>
      {/* Component for navigation button and title above the button */}
      {/* <NavigationButton
        title={lang === "English" ?
          "Now that you have considered your pain and treatments,\n let\`s go to the next step to clarify what is important to you" :
          "Maintenant que nous avons parlé de votre douleur et de vos traitements, \n passons à l’étape suivante pour explorer ce qui est important pour vous!"}
        btnText={lang === "English" ? "Continue to Step 2" : "Passez à l'étape 2"}
        handleNavigation={handleButtonNav}
      /> */}
      <Footer />
      {/* {!didSelect && <DialogBox description={lang === "English" ?
        "The selected information will be saved in the trial database. You can modify the information as needed." :
        "Les informations sélectionnées seront enregistrées dans la base de données d'essai. Vous pouvez modifier les informations selon vos besoins."}
        step='s1Trial' /> */}
      <NavigationDialog
        open={displayNavDialog}
        handleClose={closeNavDialog}
        saveHandler={saveHandler}
        isLogout={navBy}
      />
      <FailedSaveDialog open={open} setOpen={setOpen} />
    </div>
  );
};
export default withRouter(Questionnaire);
