import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import NavigationButton from "../../../components/UI/Buttons/NavigationButton";
import { NavLink as RRNav } from "react-router-dom";
import { getUserType, logout } from "../../../components/AuthenticationService";
import BottomTabNavReview from "../../../components/Review/BottomTabNavReview";
import TopBar from "../../../components/Admin/TopBar";
import Header from "../../../components/Home/Header";
import UserNav from "../../../components/UserDash/UserNav";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
  ReferenceArea,
  ResponsiveContainer
} from "recharts";
import { getRequest } from "../../../API/ApiHandler";

import "../UserPanel.css";
import IdleComponent from "../../../components/IdleComponent";
import HappyIndicatorFace from "../../../components/UI/IndicatorFaces/ChartHappyFace";
import NeutralIndicatorFace from "../../../components/UI/IndicatorFaces/ChartNeutralFace";
import PainIndicatorFace from "../../../components/UI/IndicatorFaces/ChartPainFace";
import Subheader from "../../../components/UI/Subheader/Subheader";

//Get value of language from local storage
let lang = localStorage.getItem("language");

const PainAvg = (props) => {
  const [user, setUser] = useState()
  const [logs, setLogs] = useState([])
  const [logsExport, setLogsExport] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [dates, setDates] = useState()
  const [date1, setDate1] = useState('default')
  const [date2, setDate2] = useState('default')
  const [limited, setLimited] = useState([])
  const [selectedSymptoms, setSelectedSymptoms] = useState(['pain_level'])
  const [saved, setSaved] = useState(true);
  const [navBy, setNavBy] = useState("");
  const [navTo, setNavTo] = useState('')
  const [displayNavDialog, setDisplayNavDialog] = useState(false);
  const predefinedDateRanges = [
    { label: lang === "English" ? "Last week" : "Semaine dernière", range: () => [new Date(new Date().setDate(new Date().getDate() - 7)), new Date()] },
    { label: lang === "English" ? "Last 2 weeks" : "Les 2 dernières semaines", range: () => [new Date(new Date().setDate(new Date().getDate() - 14)), new Date()] },
    { label: lang === "English" ? "Last 3 weeks" : "Les 3 dernières semaines", range: () => [new Date(new Date().setDate(new Date().getDate() - 21)), new Date()] },
    { label: lang === "English" ? "Last month" : "Mois dernier", range: () => [new Date(new Date().setMonth(new Date().getMonth() - 1)), new Date()] },
    { label: lang === "English" ? "Last 3 months" : "Les 3 derniers mois", range: () => [new Date(new Date().setMonth(new Date().getMonth() - 3)), new Date()] },
    { label: lang === "English" ? "Last 6 months" : "Les 6 derniers mois", range: () => [new Date(new Date().setMonth(new Date().getMonth() - 6)), new Date()] },
    { label: lang === "English" ? "Last 9 months" : "Les 9 derniers mois", range: () => [new Date(new Date().setMonth(new Date().getMonth() - 9)), new Date()] },
    { label: lang === "English" ? "Last year" : "Année dernière", range: () => [new Date(new Date().setFullYear(new Date().getFullYear() - 1)), new Date()] },
    { label: lang === "English" ? "Last 2 years" : "Les 2 dernières années", range: () => [new Date(new Date().setFullYear(new Date().getFullYear() - 2)), new Date()] },
  ];

  const handleStepperNav = (to) => {
    props.history.push(to);
  };

  // Define a color map for each symptom
const symptomColors = {
  pain_level: "#296d98",      // Blue for pain
  fatigue_level: "#ff6347",   // Red for fatigue
  stiffness_level: "#32cd32", // Green for stiffness
  stress_level: "#ffbf00",    // Yellow for stress
  anxiety_level: "#663399"    // Purple for anxiety
};

  //get current user's id
  const getUser = async () => {
    let response = await getRequest("/auth/users/current/me");
    let user = "";
    if (response) {
      user = response.data.id;
      setUser(user);
      getLogs(user);
    }
  };
  const handleSubHeaderAdminNav = () => {
    if (saved) {
      props.history.push(`/adminPanel/users`);
    } else {
      setNavBy("admin");
      setDisplayNavDialog(true);
    }
  };

  const handleSubHeaderProgressNav = () => {
    if (saved) {
      props.history.push(`/userPanel/average`);
    } else {
      setNavBy("user");
      setDisplayNavDialog(true);
    }
  };

  const handleSubHeaderLogout = () => {
    if (saved) {
      logout();
      props.history.push(`/`);
    } else {
      setNavBy("logout");
      setDisplayNavDialog(true);
    }
  };


  //get that user's logs
  const getLogs = async(user) => {
    const data = await getRequest(`/logs/${user}`)
    console.log(data);
    if(data) {
      data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      let updatedLog = data.map(log => {
        const options = { timeStyle: 'short', timeZone: 'UTC' }
        const time = new Date(log.createdAt).toLocaleTimeString('en-CA', options)
        const date = new Date(log.createdAt).toLocaleDateString('en-CA')
        log.date = date
        log.time = time
        return log
      })
      setLogs(updatedLog)
      getData(updatedLog)

    }
    setIsLoading(false);
  };

  //create map from logs of dates, Symptoms levels, and other treatments
  const getData = (updatedLog) => {
    const exportList = updatedLog.map((el) => {
      if (el.step_one !== null) {
        let log = {
          date: el.date,
          pain_level: el.step_one ? JSON.parse(el.step_one).pain_level ?? null : null,  // Default to null if missing
          fatigue_level: el.step_one ? JSON.parse(el.step_one).fatigue_level ?? null : null,
          stiffness_level: el.step_one ? JSON.parse(el.step_one).stiffness_level ?? null : null,
          stress_level: el.step_one ? JSON.parse(el.step_one).stress_level ?? null : null,
          anxiety_level: el.step_one ? JSON.parse(el.step_one).anxiety_level ?? null : null,
          treatment: el.step_one ? JSON.parse(el.step_one).other_treatments : '',
          med: el.step_one ? JSON.parse(el.step_one).selected_meds : ''
        };
        return log;
      }
      return null; // Remove invalid logs
    }).filter(Boolean); // Remove null logs
  
    setLogsExport(exportList);
    removeNull(exportList);
  };  

  //remove any null/undefined entries from the above
  const removeNull = (exportList) => {
    exportList = exportList.filter(function(element){
      return element !== undefined
    })
    setLogsExport(exportList)
    firstTen(exportList)
    getDates(exportList)
    getDates2(exportList)
    return exportList
  }


  //use ten most recent logs to populate graph
  const firstTen = (exportList) => {
    if (exportList.length <= 10) {
      setLimited(exportList);
    } else {
      let firstTen = [];
      for (var i = 0; i < 10; i++) {
        firstTen.push(exportList[i]);
      }
      setLimited(firstTen);
    }
  };

  //get just the dates from those logs for dropdown
  const getDates = (exportList) => {
    const datelist = exportList.map(el => {
        if (el !== null && el.length !== 0) {
            let dates = {
                date: el.date,
            };
            return dates;
        }
    });

    const noDups = [...new Map(datelist.map(item => [JSON.stringify(item), item])).values(),];

    const sortedDates = noDups.sort((a, b) => {
        const dateA = new Date(a.date).getTime();
        const dateB = new Date(b.date).getTime();
        return dateB - dateA; 
    });

    setDates(sortedDates);
    setIsLoading(false);
};

  const getDates2 = (exportList) => {
    const predefinedDates = predefinedDateRanges.map(option => ({ date: option.label }));
    const datelist = exportList.map(el => {
        if (el !== null && el.length !== 0) {
            let dates = {
                date: el.date
            };
            return dates;
        }
    });
  };
  const handleDate = (e) => {
    const selectedOption = e.target.value;
    const predefinedOption = predefinedDateRanges.find(option => option.label === selectedOption);
    if (predefinedOption) {
      const [startDate, endDate] = predefinedOption.range();
      setDate1(startDate.toISOString().split('T')[0]);
      setDate2(endDate.toISOString().split('T')[0]);
    }
  };
  


  //state change of first dropdown
  const handleDate1 = (e) => {
    setDate1(e.target.value);
  };

  useEffect(() => {
    if (date1 === "default") {
      setDate2("default");
    } else {
      if (date2 === "default") {
        setDate2(dates[0].date);
      } else {
        onClick(date1, date2, logsExport);
      }
    }
  }, [date1]);

  useEffect(() => {
    if (date2 === "default") {
      firstTen(logsExport);
    } else {
      onClick(date1, date2, logsExport);
    }
  }, [date2]);

  //state change of second dropdown
  const handleDate2 = (e) => {
    setDate2(e.target.value);
    onClick(date1, date2, logsExport);
  };

  // Handle the checkbox change
  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;

    // If the checkbox is checked, add the value to the selectedSymptoms array
    if (checked) {
      setSelectedSymptoms((prevSelectedSymptoms) => [...prevSelectedSymptoms, value]);
    } else {
      // If unchecked, remove the value from the selectedSymptoms array
      setSelectedSymptoms((prevSelectedSymptoms) => prevSelectedSymptoms.filter(symptom => symptom !== value));
    }
  };

  //onClick to load logs in range of selected dates
  const onClick = (d1, d2, logs) => {
    if (d1 === "default" || d2 === "default") {
      return;
    }
    let dateRange = [];
    var d1 = Date.parse(d1);
    var d2 = Date.parse(d2);
    logs.map((el) => {
      var logDate = Date.parse(el.date);
      if (d1 <= logDate && logDate <= d2) {
        dateRange.push(el);
      } else if (d2 <= logDate && logDate <= d1) {
        dateRange.push(el);
      }
    });
    setLimited(dateRange);
  };


  const Emoji = props => (

    <span
      className={`emoji ${props.indicator}`}
      role="img"
      aria-label={props.label ? props.label : ""}
      aria-hidden={props.label ? "false" : "true"}
    >
      {props.symbol}
    </span>
  );

  useEffect(() => {
    setIsLoading(true);
    getUser();
    if (user) getLogs();
  }, [user]);

  const CustomTooltip = ({ active, payload, coordinate, chartWidth }) => {
    if (active && payload && payload.length) {
      // Default horizontal offset
      const isLeftSide = coordinate.x < chartWidth / 2;
      const offsetX = isLeftSide ? 100 : -100;
      const offsetY = -5;

      // Calculate the final offset to prevent right edge overflow
    const tooltipWidth = 250; // Approximate width of tooltip
    const calculatedLeft = coordinate.x + offsetX;
    const finalOffsetX = (!isLeftSide && calculatedLeft + tooltipWidth > window.innerWidth)
      ? -tooltipWidth - 5  // Shift left to prevent right edge overflow
      : offsetX;

      

      
    let meds = [];
    //fr meds not in the log therefore  using the english ones for both.
    for (var i = 0; i < payload[0].payload.med.length; i++) {
      lang === "English"
        ? meds.push(" " + payload[0].payload.med[i].name)
        : meds.push(" " + payload[0].payload.med[i].name);
    }
      

    let treatments = [];
    for (var i = 0; i < payload[0].payload.treatment.length; i++) {
      lang === "English"
        ? treatments.push(" " + payload[0].payload.treatment[i].name)
        : treatments.push(" " + payload[0].payload.treatment[i].fr_name);
    }

    return (
      <div
        className="painAvg-custom-tooltip"
        style={{
          position: "absolute",
          transform: `translate(${coordinate.x + finalOffsetX}px, ${coordinate.y + offsetY}px)`,
          backgroundColor: "#f5f3f0",
          border: "1px solid #ccc",
          borderRadius: "8px",
          padding: "12px",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
          pointerEvents: "none",
          zIndex: 1000,
          minWidth: "250px",
          maxWidth: "400px",
          display: "block",
          wordWrap: "break-word",
        }}
      >
        <p>
          <span style={{ fontWeight: "bold" }}>
            {lang === "English" ? "Prescribed Arthritis Medication Used" : "Médicaments contre l'arthrite prescrits utilisés"}
            :
          </span>
          {` ${meds.length !== 0 ? meds : lang === "English" ? 'None' : 'Aucun(e)'}`}
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>
            {lang === "English" ? "Other Treatments Used" : "Autres traitements utilisés"}
            :
          </span>
          {` ${treatments.length !== 0 ? treatments : lang === "English" ? 'None' : 'Aucun(e)'}`}
        </p>
      </div>
    );
  }
  return null;
};


  return (
    <>
      <IdleComponent history={props.history} />
        <div className="user-container">
      <div className='page-header'>
        <Header
          current={props}
          handleNav={handleStepperNav}
          setNavTo={setNavTo}
        />
        </div>
<div className="page-subheader">        <Subheader
            title={lang === "English" ? "Track Symptoms" : "Suivre les symptômes"}
            handleAdmin={handleSubHeaderAdminNav}
            handleUser={handleSubHeaderProgressNav}
            handleLogout={handleSubHeaderLogout}
          />
          </div>
        
          
          <div className="user-nav">

        <UserNav />

        <div className="user-body">

        <div
          style={{
            display: "flex",
            marginTop: "auto",
            padding: "1rem",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <Button className="next-btn" tag={RRNav} to="/make-your-plan">
            {lang === "English" ? "Back" : "Retour"}
          </Button>
          <Button className="next-btn" tag={RRNav} to="/userPanel/manage">
            {lang === "English" ? "Next" : "Suivant(e)"}
          </Button>
        </div>
        </div>
        <div className="user-body">
          <div className="captions" >
            <h3><b>{lang === "English" ? "Symptoms over time" : "Symptômes à travers le temps"}</b></h3>
            <p>{lang === "English" ? "Here you can review how much symptoms on average you have had because of your arthritis." : 
            "Ici vous pouvez évaluer la moyenne des symptômes que vous avez ressentie à cause de votre arthrite"}</p>
            <p>{lang === "English" ? "The values on this graph are taken directly from your past sessions using the JIA Option Map." : 
            "Les valeurs de ce graphique sont tirées directement de vos sessions passées dans la Carte d'options en AJI."}</p>
            <p>{lang === "English" ? "You can hover over any point on the graph to see the date at which you put this information, your symptoms, and the treatments you were using at that time!" : 
            "Vous pouvez survoler n'importe quel point du graphique pour voir la date à laquelle vous avez mis cette information, vos symptômes et les traitements que vous utilisiez à ce moment-là!"}</p>

          </div>

          <div className={"chartWlegend"}>
            <div className="legend">
              <h5>
                <b>{lang === "English" ? "Legend" : "Légende"}</b>
              </h5>
              <div className="emojis">
                <div className={"levels"}>
                  <PainIndicatorFace /> <h6>{`${lang === "English" ? '4-5 => Very Severe Symptoms' : '4-5 => Symptômes très sévères'}`}</h6>
                </div>
                <div className={"levels"}>
                  <NeutralIndicatorFace /> <h6>{`${lang === "English" ? '2-3 => Moderate Symptoms' : '2-3 => Symptômes moyens'}`}</h6>
                </div>
                <div className={"levels"}>
                  <HappyIndicatorFace /> <h6>{`${lang === "English" ? '1 => No Symptoms' : '1 => Pas de symptômes'}`}</h6>

                </div>
              </div>
            </div>

            <div className={"chartWrapper"} style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
            <div
  className={"multiSelectSymptoms"}
  style={{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    textAlign: "center",
  }}
>
  <label
    style={{
      fontSize: "35px",
      fontWeight: "bold",
      marginBottom: "20px",
    }}
  >
    {lang === "English" ? "Select Symptoms:" : "Sélectionnez les symptômes:"}
  </label>

    <div className="checkboxes" style={{ display: "flex", justifyContent: "center", gap: "15px" }}>
      <label style={{ color: symptomColors["pain_level"], fontSize: "20px", display: "flex", alignItems: "center" }}>
        <input
          type="checkbox"
          value="pain_level"
          checked={selectedSymptoms.includes("pain_level")}
          onChange={(e) => handleCheckboxChange(e)}
          style={{ transform: "scale(1.0)", marginRight: "8px" }}
        />
        {lang === "English" ? "Pain" : "Douleur"}
      </label>

      <label style={{ color: symptomColors["fatigue_level"], fontSize: "20px", display: "flex", alignItems: "center" }}>
        <input
          type="checkbox"
          value="fatigue_level"
          checked={selectedSymptoms.includes("fatigue_level")}
          onChange={(e) => handleCheckboxChange(e)}
          style={{ transform: "scale(1.0)", marginRight: "8px" }}
        />
        {lang === "English" ? "Fatigue" : "Fatigue"}
      </label>

      <label style={{ color: symptomColors["stiffness_level"], fontSize: "20px", display: "flex", alignItems: "center" }}>
        <input
          type="checkbox"
          value="stiffness_level"
          checked={selectedSymptoms.includes("stiffness_level")}
          onChange={(e) => handleCheckboxChange(e)}
          style={{ transform: "scale(1.0)", marginRight: "8px" }}
        />
        {lang === "English" ? "Stiffness" : "Raideurs"}
      </label>

      <label style={{ color: symptomColors["stress_level"], fontSize: "20px", display: "flex", alignItems: "center" }}>
        <input
          type="checkbox"
          value="stress_level"
          checked={selectedSymptoms.includes("stress_level")}
          onChange={(e) => handleCheckboxChange(e)}
          style={{ transform: "scale(1.0)", marginRight: "8px" }}
        />
        {lang === "English" ? "Stress" : "Stress"}
      </label>

      <label style={{ color: symptomColors["anxiety_level"], fontSize: "20px", display: "flex", alignItems: "center" }}>
        <input
          type="checkbox"
          value="anxiety_level"
          checked={selectedSymptoms.includes("anxiety_level")}
          onChange={(e) => handleCheckboxChange(e)}
          style={{ transform: "scale(1.0)", marginRight: "8px" }}
        />
        {lang === "English" ? "Anxiety" : "Anxiété"}
      </label>
    </div>
  </div>


              <div className={"intervalDropdown"}>
                <div className={lang === "English" ? "chooseTimes1_en" : "chooseTimes1_fr"}>
                  <select disabled={isLoading} onChange={handleDate1} className="select_date">
                    <option value='default'>{lang === "English" ? "-- Select a date --" : "-- Sélectionnez une date --"}</option>
                    {dates!==undefined && dates.length!==0 && dates.map((list) => <option key={list.date} value={list.date} selected={list.date === date1}>{list.date}</option>)}
                  </select>
                </div>

                <div className={lang === "English" ? "chooseTimes2_en" : "chooseTimes2_fr"}>
                  <select disabled={isLoading} onChange={handleDate2} className="select_date">
                    <option value='default'>{lang === "English" ? "-- Select a date --" : "-- Sélectionnez une date --"}</option>
                    {dates!==undefined && dates.length!==0 && dates.map((list) => <option key={list.date} value={list.date} selected={list.date === date2}>{list.date}</option>)}

                  </select>
                </div>
                <div className={lang === "English" ? "chooseTimes_en" : "chooseTimes_fr"}>
                  <select disabled={isLoading} onChange={handleDate} className="select_date">
                    <option value="default">{lang === "English" ? "-- Select a date range --" : "-- Sélectionnez une plage de dates --"}</option>
                    {predefinedDateRanges.map((range) => (<option key={range.label} value={range.label}>{range.label}</option>))}                  
                  </select>
                </div>
              </div>
              
              

              <ResponsiveContainer width="95%" height={400}>
                <LineChart
                  className="chart"
                  data={limited}
                  margin={{ top: 5, right: 50, left: 20, bottom: 20 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" label={{value:'Date', position:"bottom", offset:0}} reversed/>
                  <YAxis dataKey="level" tickCount={5} domain={[1,5]} label={{value: lang === "English" ? "Average Symptoms" : "Symptômes Moyens", position:"insideCenter", angle:-90}}/>
                  <Tooltip content={<CustomTooltip />} />
                  {selectedSymptoms
                  .filter((symptom) => limited.some((log) => log[symptom] !== null)) // Ensure the symptom has valid data
                  .map((symptom, index) => (
  <Line
    key={symptom}
    type="monotone"
    dataKey={symptom}
    stroke={symptomColors[symptom]} // Use the fixed color for each symptom
    strokeWidth={3}
    connectNulls={true} // Connect points with null values to avoid gaps
    activeDot={{ r: 8 }}
  />
))}
               <ReferenceLine y={2.5} strokeDasharray="5 5" stroke="#000000"/>
                  

                  <defs>
                    <linearGradient id="colorGY" x1="0" y1="1" x2="0" y2="0">
                      <stop offset="5%" stopColor="#03c04a" stopOpacity={1} />
                      <stop offset="95%" stopColor="#effd5f" stopOpacity={1} />
                    </linearGradient>
                  </defs>

                  <defs>
                    <linearGradient id="colorYR" x1="0" y1="1" x2="0" y2="0">
                      <stop offset="5%" stopColor="#effd5f" stopOpacity={1} />
                      <stop offset="95%" stopColor="#e3242b" stopOpacity={1} />
                    </linearGradient>
                  </defs>

                  <ReferenceArea
                    y1={1}
                    y2={2}
                    fill="url(#colorGY)"
                    opacity={1}
                  />
                  <ReferenceArea y1={2} y2={3} fill="#effd5f" opacity={1} />
                  <ReferenceArea
                    y1={3}
                    y2={5}
                    fill="url(#colorYR)"
                    opacity={1}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
      </div>
      <BottomTabNavReview current={props} />
        <div
          style={{
            display: "flex",
            marginTop: "auto",
            padding: "1rem",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <Button className="next-btn" tag={RRNav} to="/make-your-plan">
            {lang === "English" ? "Back" : "Retour"}
          </Button>
          <Button className="next-btn" tag={RRNav} to="/userPanel/manage">
            {lang === "English" ? "Next" : "Suivant(e)"}
          </Button>
        </div>
    </>
  );
};

export default PainAvg;
