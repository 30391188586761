import TextField from "@material-ui/core/TextField";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useBeforeunload } from "react-beforeunload";
import { NavLink as RRNav } from "react-router-dom";
import { Button } from "reactstrap";
import { getRequest, postRequest } from "../../API/ApiHandler";
import { getUserType, logout } from "../../components/AuthenticationService";
import Footer from "../../components/Footer";
import { handleRecommendations } from "../../components/GenerateRecommendations";
import { updateLogPrefs } from "../../components/HandleUserLog";
import Header from "../../components/Home/Header";
import IdleComponent from "../../components/IdleComponent";
import DialogBox from "../../components/UI/DialogBox";
import FailedSaveDialog from "../../components/UI/FailedSaveDialog";
import NavigationDialog from "../../components/UI/NavigationDialog";
import SaveButton from "../../components/UI/SaveButton";
import CircleControl from "../../components/UI/Slider/CircleControl";
import Subheader from "../../components/UI/Subheader/Subheader";

const theme = createTheme({
  palette: {
    primary: { main: "#10434F" }
  }
});

const WhatsImportant = (props) => {
  const [sliders, setSliders] = useState([]);
  const [preferenceText, setPreferenceText] = useState();
  const [saved, setSaved] = useState(true);
  const [displayNavDialog, setDisplayNavDialog] = useState(false);
  const [navBy, setNavBy] = useState("");
  const [navTo, setNavTo] = useState("");
  const [didSelect, setDidSelect] = useState(false);
  const [saveStatus, setSaveStatus] = useState("default");
  const [open, setOpen] = useState(false);
  const [other, setOther] = useState();
  const [isLoading, setIsLoading] = useState();

  // Check if there are unsaved changed when user attempts to close or refresh page and display a message.
  useBeforeunload((event) => {
    if (!saved) event.preventDefault();
  });

  React.useEffect(() => {
    getUserType();
  });

  // Get the what's important sliders from the DB
  const getPrefs = async () => {
    let data = await getRequest("/preferences");
    if (data) data.forEach((el) => (el.value = 0));
    setSliders((prefs) => [...prefs, ...data]);
    setIsLoading(false);
  };

  const getUserPrefs = async () => {
    let preferences = await getRequest(`/userPreferences/user`);
    if (preferences) {
      const prefs = preferences.map((pref) => {
        return {
          id: pref.user_preference.preferenceId,
          value: pref.user_preference.value
        };
      });
      let setUserValues = sliders.map((slider) => {
        prefs.forEach((el) => {
          if (el.id === slider.id) slider.value = el.value;
        });
        return slider;
      });
      setSliders(setUserValues);
    }
  };

  const getPreferenceText = async () => {
    let text = await getRequest(`/preferenceText/user`);
    if (text) setPreferenceText(text.text);
  };

  const saveHandler = async () => {
    await Promise.all([savePrefs(), savePrefText()]).then(async (data) => {
      let failed = data.indexOf() !== -1;
      if (!failed) {
        await handleRecommendations();
        updateLog();
        setSaveStatus("success");
        setSaved(true);
      } else {
        setSaveStatus("default");
        setOpen(true);
      }
    });
  };

  const updateLog = () => {
    let prefs = sliders.map((el) => {
      let data = {
        description: el.description,
        value: el.value
      };
      return data;
    });

    const data = {
      prefs: prefs,
      preference_text: preferenceText
    };
    updateLogPrefs(data);
  };

  const savePrefs = () => {
    const input = { sliders: sliders };
    return postRequest("/userPreferences", input, setSaveStatus);
  };

  const savePrefText = () => {
    const input = { text: preferenceText };
    return postRequest("/preferenceText", input);
  };

  const setValue = (value, ...others) => {
    const id = others[0];
    sliders.forEach((slider) => {
      if (id === slider.id) {
        slider.value = value;
      }
    });
    setSaved(false);
    setSaveStatus("default");
  };

  const handleTextChange = (ev) => {
    const text = ev.target.value;
    setPreferenceText(text);
    setSaved(false);
    setSaveStatus("default");
  };

  const handleButtonNav = () => {
    if (saved) {
      props.history.push(`/review-and-select`);
    } else {
      setNavBy("button");
      setDisplayNavDialog(true);
    }
  };

  const handleStepperNav = (to) => {
    if (saved) {
      props.history.push(to);
    } else {
      setNavBy("stepper");
      setDisplayNavDialog(true);
    }
  };

  const handleSubHeaderAdminNav = () => {
    if (saved) {
      props.history.push(`/adminPanel/users`);
    } else {
      setNavBy("admin");
      setDisplayNavDialog(true);
    }
  };

  const handleSubHeaderProgressNav = () => {
    if (saved) {
      props.history.push(`/userPanel/average`);
    } else {
      setNavBy("user");
      setDisplayNavDialog(true);
    }
  };

  const handleSubHeaderLogout = () => {
    if (saved) {
      logout();
      props.history.push(`/`);
    } else {
      setNavBy("logout");
      setDisplayNavDialog(true);
    }
  };

  const closeNavDialog = (selection) => {
    selection === 0
      ? setDisplayNavDialog(false)
      : navBy === "button"
      ? props.history.push(`/review-and-select/recommendations`)
      : navBy === "admin"
      ? props.history.push(`/adminPanel/users`)
      : navBy === "user"
      ? props.history.push(`/userPanel/average`)
      : navBy === "logout"
      ? props.history.push(`/`)
      : props.history.push(navTo);
  };

  //=============checked to change color

  const checkedZero = (ev) => {
    let buttonList = document.querySelectorAll(".StepFour-div-other div");
    buttonList.forEach((button) => {
      if (button.style.backgroundColor) button.style.backgroundColor = "";
    });
    ev.target.style.backgroundColor = "#008000";
    //10434F

    let medVal = ev.target.getAttribute("value");
    setOther(medVal);

    setSaved(false);
    setSaveStatus("default");
  };

  const checkedOne = (ev) => {
    let buttonList = document.querySelectorAll(".StepFour-div-other div");
    buttonList.forEach((button) => {
      if (button.style.backgroundColor) button.style.backgroundColor = "";
    });
    ev.target.style.backgroundColor = "#8FE381";
    //10434F

    let medVal = ev.target.getAttribute("value");
    setOther(medVal);

    setSaved(false);
    setSaveStatus("default");
  };

  const checkedTwo = (ev) => {
    let buttonList = document.querySelectorAll(".StepFour-div-other div");
    buttonList.forEach((button) => {
      if (button.style.backgroundColor) button.style.backgroundColor = "";
    });
    ev.target.style.backgroundColor = "#B0F5AB";
    //10434F

    let medVal = ev.target.getAttribute("value");
    setOther(medVal);

    setSaved(false);
    setSaveStatus("default");
  };

  const checkedThree = (ev) => {
    let buttonList = document.querySelectorAll(".StepFour-div-other div");
    buttonList.forEach((button) => {
      if (button.style.backgroundColor) button.style.backgroundColor = "";
    });
    ev.target.style.backgroundColor = "#ffff7f";
    //10434F

    let medVal = ev.target.getAttribute("value");
    setOther(medVal);

    setSaved(false);
    setSaveStatus("default");
  };

  const checkedFour = (ev) => {
    let buttonList = document.querySelectorAll(".StepFour-div-other div");
    buttonList.forEach((button) => {
      if (button.style.backgroundColor) button.style.backgroundColor = "";
    });
    ev.target.style.backgroundColor = "#FC6C85";
    //10434F

    let medVal = ev.target.getAttribute("value");
    setOther(medVal);

    setSaved(false);
    setSaveStatus("default");
  };
  const checkedFive = (ev) => {
    let buttonList = document.querySelectorAll(".StepFour-div-other div");
    buttonList.forEach((button) => {
      if (button.style.backgroundColor) button.style.backgroundColor = "";
    });
    ev.target.style.backgroundColor = "#FF0000";
    //10434F

    let medVal = ev.target.getAttribute("value");
    setOther(medVal);

    setSaved(false);
    setSaveStatus("default");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (localStorage.getItem("s2Trial")) setDidSelect(true);
    setIsLoading(true);
    getPrefs();
    getPreferenceText();
  }, []);

  useEffect(() => {
    if (!isLoading) getUserPrefs();
  }, [isLoading]);

  //Get value of language from local storage
  let lang = localStorage.getItem("language");

  return (
    <div className="wrapper">
      <IdleComponent history={props.history} saveHandler={saveHandler} />
      <div className="page-header">
        <Header
          current={props}
          handleNav={handleStepperNav}
          setNavTo={setNavTo}
        />
      </div>
      <div className="page-subheader">
        <Subheader
          title={
            lang === "English"
              ? "What’s important to you when managing your symptoms?"
              : "Qu’est-ce qui est important pour vous afin de soulager vos symptômes?"
          }
          handleAdmin={handleSubHeaderAdminNav}
          handleUser={handleSubHeaderProgressNav}
          handleLogout={handleSubHeaderLogout}
        />
      </div>
      <div className="body-container">
        <div
          style={{
            display: "flex",
            marginTop: "auto",
            padding: ".5rem",
            alignItems: "center"
          }}
        >
          <Button className="next-btn" tag={RRNav} to="/questionnaire2">
            {lang === "English" ? "Back" : "Retour"}
          </Button>
          <SaveButton
            saveHandler={saveHandler}
            saveStatus={saveStatus}
            loc={"tp"}
          />
          <Button
            className="next-btn"
            tag={RRNav}
            to="/review-and-select/recommendations"
            onClick={() => {
              if (!saved) {
                saveHandler();
              }
            }}
          >
            {lang === "English" ? "Next" : "Suivant(e)"}
          </Button>
        </div>
        <div className="notice summary-notice">
          <div>
            <h4>
              {lang === "English"
                ? "The choices you will make on this page will decide which treatments will be shown to you in the next step"
                : "Les choix que vous ferez sur cette page vont déterminer les suggestions de traitements qui vous seront présentés à la prochaine étape"}
            </h4>
          </div>
        </div>
        <div className="slider-component-div box-container">
          {sliders.length &&
            sliders.map((slider, index) => (
              <div key={slider.id}>
                <h4>
                  {index + 1}.{" "}
                  {lang === "English"
                    ? slider.description
                    : slider.fr_description}
                </h4>
                <CircleControl
                  setValue={setValue}
                  minLabel={
                    lang === "English"
                      ? slider.left_label
                      : slider.fr_left_label
                  }
                  maxLabel={
                    lang === "English"
                      ? slider.right_label
                      : slider.fr_right_label
                  }
                  sliderId={slider.id}
                  reversed={slider.reversed}
                  value={slider.value}
                ></CircleControl>
              </div>
            ))}
          <h4>
            {lang === "English"
              ? "7. Are there other things that are important to you when choosing a treatment?"
              : "7. Y a-t-il autre chose qui est important lorsque vous choisissez un traitement?"}
          </h4>
          <ThemeProvider theme={theme}>
            <Formik>
              <Form className="form-content">
                <Field
                  name="painTreatment"
                  onChange={handleTextChange}
                  className="text-field"
                  value={preferenceText}
                  component={TextField}
                  variant="outlined"
                  id="consider-other-factors"
                  multiline
                  rows="6"
                  label={
                    lang === "English"
                      ? "If so, please describe"
                      : "Si oui, veuillez décrire"
                  }
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Form>
            </Formik>
          </ThemeProvider>
        </div>

        <div
          style={{
            display: "flex",
            marginTop: "auto",
            padding: ".5rem",
            alignItems: "center"
          }}
        >
          <Button className="next-btn" tag={RRNav} to="/questionnaire2">
            {lang === "English" ? "Back" : "Retour"}
          </Button>
          <SaveButton
            saveHandler={saveHandler}
            saveStatus={saveStatus}
            loc={"tp"}
          />
          <Button
            className="next-btn"
            tag={RRNav}
            to="/review-and-select/recommendations"
            onClick={() => {
              if (!saved) {
                saveHandler();
              }
            }}
          >
            {lang === "English" ? "Next" : "Suivant(e)"}
          </Button>
        </div>
      </div>
      {/* Component for navigation button and title above the button */}
      <Footer />
      {!didSelect && (
        <DialogBox
          description={
            lang === "English"
              ? "The selected information will be saved in the trial database. You can modify the information as needed."
              : "Les informations sélectionnées seront enregistrées dans la base de données d'essai. Vous pouvez modifier les informations selon vos besoins."
          }
          step="s2Trial"
        />
      )}
      <NavigationDialog
        open={displayNavDialog}
        handleClose={closeNavDialog}
        saveHandler={saveHandler}
        saveStatus={saveStatus}
        isLogout={navBy}
      />
      <FailedSaveDialog open={open} setOpen={setOpen} />
    </div>
  );
};

export default WhatsImportant;