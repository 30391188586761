import React from "react";
import { NavLink } from "react-router-dom";
import "./UserNav.css";

//Get value of language from local storage
let lang = localStorage.getItem("language");

const UserNav = (props) => {
  const handleUserState = () => {
    if (props.onCloseUser) props.onCloseUser(false, null);
  };

  return (
    <div className="side-nav-container-user">
      <div className="fixed-content-user">
        <div className="list-container-user">
          <ul className="list-user">
            <li className="list-user-item">
              <NavLink to="/userPanel/average" onClick={handleUserState}>
                {lang === "English"
                  ? "Symptoms over time"
                  : "Symptômes à travers le temps"}
              </NavLink>
            </li>
            <li className="list-user-item">
              <NavLink to="/userPanel/manage">
                {lang === "English"
                  ? "Impact of treatment"
                  : "Impact du traitement"}
              </NavLink>
            </li>
            <li className="list-user-item">
              <NavLink to="/userPanel/treatments">
                {lang === "English"
                  ? "Planned and used treatments"
                  : "Traitements prévus et utilisés"}
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default UserNav;
