import React, { useEffect, useState } from "react";
import { getRequest } from "../../API/ApiHandler";
import Spinner from "../UI/Spinner";
import "./Summary.css";

const StepOne = () => {
  // Q2 pain areas values
  const [painAreas, setPainAreas] = useState([]);
  // Q3 selected meds
  const [prescribedMeds, setPrescribedMeds] = useState([]);
  const [otherMeds, setOtherMeds] = useState([]);
  // Q4 other selected meds and treatments
  const [treatments, setTreatments] = useState([]);
  // Q5 how often do you use your treatments values
  const [howOftenMeds, setHowOftenMeds] = useState();
  const [howOftenTreats, setHowOftenTreats] = useState();
  // Q6 how well does your treatment
  const [controlArthritis, setControlArthritis] = useState();
  const [managePain, setManagePain] = useState();

  const [isLoading, setIsLoading] = useState(true);

  const [symptoms, setSymptoms] = useState({});

  let qNum = 0;

  const updateSymptom = (key, field, value) => {
    setSymptoms((prevSymptoms) => ({
      ...prevSymptoms,
      [key]: {
        ...prevSymptoms[key],
        [field]: value
      }
    }));
  };

  const symptomFields = {
    selected: false,
    level: 1
  };

  const getSymptoms = async () => {
    try {
      const { data } = await getRequest(`/symptoms`);

      const symptomData = {};

      for (const symptom of data) {
        symptomData[symptom.name.toLowerCase()] = {
          ...symptomFields,
          fr_name: symptom.fr_name.toLowerCase(),
          question: symptom.question,
          fr_question: symptom.fr_question
        };
      }

      setSymptoms(symptomData);
    } catch (error) {
      console.error("Error fetching symptoms:", error);
    }
  };

  const getPainAreas = async () => {
    let items = await getRequest(`/painAreas/user`);
    if (items) {
      let { data } = items;
      const selectedAreas = [];
      for (let part in data) {
        if (data[part] === true) selectedAreas.push(part);
      }
      setPainAreas(selectedAreas);
    }
  };

  const getUserSymptoms = async () => {
    try {
      let sessionModifiedSymptoms =
        JSON.parse(sessionStorage.getItem("selectedSymptoms")) || [];

      if (sessionModifiedSymptoms.length === 0) {
        const { data } = await getRequest("/symptoms/");

        if (data && Array.isArray(data)) {
          sessionModifiedSymptoms = data.map((symptom) => symptom.name);
          sessionStorage.setItem(
            "selectedSymptoms",
            JSON.stringify(sessionModifiedSymptoms)
          );
        }
      }

      const modifiedSymptomsMap = sessionModifiedSymptoms.reduce(
        (acc, symptom) => {
          acc[symptom.toLowerCase()] = { name: symptom };
          return acc;
        },
        {}
      );

      const promises = sessionModifiedSymptoms.map(async (symptom) => {
        const symptomName = symptom.toLowerCase();
        try {
          const { data } = await getRequest(
            `/symptomQuestions/${symptomName}/user`
          );

          if (data && modifiedSymptomsMap[symptomName]) {
            updateSymptom(symptomName, "selected", true);
            updateSymptom(symptomName, "level", data.symptom_level || 1);
          }
        } catch (err) {
          console.error("Error fetching symptom data:", err);
        }
      });

      await Promise.all(promises);
    } catch (error) {
      console.error("Error in getUserSymptoms:", error);
    }
  };

  const getSelectedMeds = async () => {
    const meds = await getRequest(`/userMedications/user`);
    if (meds) {
      const { data } = meds;
      const prescribed = data.filter(
        (med) => med.classification.section === "prescribed"
      );
      const other = data.filter(
        (med) => med.classification.section === "other"
      );
      setPrescribedMeds(prescribed);
      setOtherMeds(other);
    }
    setIsLoading(false);
  };

  const getSelectedTreatments = async () => {
    const treatments = await getRequest(`/userTreatments/user`);
    if (treatments) {
      let { data } = treatments;
      setTreatments(data);
    }
    setIsLoading(false);
  };

  const getHowOften = async () => {
    let data = await getRequest(`/frequently/user`);
    if (data) {
      if (data.data.frequently) {
        setHowOftenMeds(
          lang === "English"
            ? data.data.frequently.prescribed_meds
            : data.data.frequently.fr_prescribed_meds
        );
        setHowOftenTreats(
          lang === "English"
            ? data.data.frequently.other_treatments
            : data.data.frequently.fr_other_treatments
        );
      }
    }
    setIsLoading(false);
  };

  const getHowWell = async () => {
    const data = await getRequest(`/effectiveness/user`);
    if (data) {
      const {
        data: { control_arthritis, manage_pain }
      } = data;
      setControlArthritis(control_arthritis);
      setManagePain(manage_pain);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const fetchAllData = async () => {
      setIsLoading(true);

      try {
        await Promise.all([
          getSymptoms(),
          getUserSymptoms(),
          getPainAreas(),
          getSelectedMeds(),
          getSelectedTreatments(),
          getHowOften(),
          getHowWell()
        ]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }

      setIsLoading(false);
    };

    fetchAllData();
  }, []);

  //Get value of language from local storage
  let lang = localStorage.getItem("language");

  // Message to display if no input was provided
  const notProvidedEN = "No Input was provided";
  const notProvidedFR = "Aucune information n’a été fournie.";

  const error = lang === "English" ? "Unknown" : "Inconnue";

  //An english to french translation used in the pain Areas funtion below
  const translations = {
    right_ankle: "Cheville droite",
    right_shoulder: "Épaule droite",
    right_elbow: "Coude droit",
    right_wrist: "Poignet droit",
    right_knee: "Genou droit",
    left_ankle: "Cheville gauche",
    left_shoulder: "Épaule gauche",
    left_elbow: "Coude gauche",
    left_wrist: "Poignet gauche",
    left_knee: "Genou gauche",
    right_hip: "Hanche droit",
    left_hip: "Hanche gauche",
    jaw: "Mâchoire",
    lower_back: "Lombes",
    upper_back: "Le haut du dos",
    neck: "Cou",
    left_fingers: "Doigts gauche",
    right_fingers: "Doigts droit",
    left_toes: "Orteils gauche",
    right_toes: "Orteils droit"
  };
  const translateToFrench = (word) => {
    return translations[word] || word; // Keep the original word if no translation is found
  };

  // Pain Areas
  //Creates a list for every body part that was selected to have arthritis symptoms, capitilizes the first letter and removes any potention underscores "_"
  //If you ever need to add to the db in the future just make sure you follow what's already there (no caps and use underscores to seperate the words) and add to the above translation
  const painAreasItem = painAreas.length ? (
    <div className="pain-area-item">
      {painAreas.map((area, i) => (
        <span key={i}>
          {lang === "English"
            ? (i ? ", " : "") +
              (area[0].toUpperCase() + area.slice(1)).replaceAll("_", " ")
            : (i ? ", " : "") + translateToFrench(area)}
        </span>
      ))}
    </div>
  ) : (
    <p className="notFilled">
      {lang === "English" ? notProvidedEN : notProvidedFR}
    </p>
  );

  const getMinLabel = (symptomType, frSymptomType) => {
    if (lang === "English") {
      let label = titleCase(symptomType);
      return "No " + label;
    }

    let label = frSymptomType;

    if ("aeiou".includes(frSymptomType.charAt(0).toLocaleLowerCase())) {
      return "Pas d'" + label;
    }
    return "Pas de " + label;
  };

  const getMaxLabel = (symptomType, frSymptomType) => {
    if (lang === "English") {
      let label = titleCase(symptomType);
      return "Very Severe " + label;
    }
    let label = titleCase(frSymptomType) + " très sévère";

    if (frSymptomType.toLowerCase() === "raideurs") {
      label = label + "s";
    }

    return label;
  };
  const titleCase = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const symptomItem = (
    symptom,
    symptomFr,
    level,
    qNum,
    question,
    questionFr
  ) => {
    return (
      <div>
        <h4 style={{ fontWeight: "bold", color: "var(--primary-colour)" }}>
          {lang === "English"
            ? titleCase(`${symptom}`)
            : titleCase(`${symptomFr}`)}
        </h4>
        <h5 className="subheader-summary">
          {lang === "English"
            ? `${qNum}. ${question}`
            : `${qNum}. ${questionFr}`}
        </h5>
        {level != null ? (
          <div className="text-center">
            <div className="circle-radio-group-summary-no-zero">
              <h5 style={{ color: "#4DAA33" }}>
                {getMinLabel(symptom, symptomFr)}
              </h5>
              <h5 style={{ color: "#B10921" }}>
                {getMaxLabel(symptom, symptomFr)}
              </h5>
            </div>
            <div className="circle-radio-group-container-no-zero">
              <div className="number-rating">{level} / 5</div>
              <img
                className="circle-radio-group-img-no-zero"
                src={require(`../../img/${level}_no_zero.png`)}
                alt="How much pain on average"
              />
            </div>
          </div>
        ) : (
          <p className="notFilled">
            {lang === "English" ? notProvidedEN : notProvidedFR}
          </p>
        )}
      </div>
    );
  };
  const painAreaBuilder = () => {
    return (
      <h5 className="subheader-summary">
        {lang === "English"
          ? `2. Where is your arthritis pain?`
          : `2. Où avez-vous de la douleur due à votre arthrite?`}
      </h5>
    );
  };
  const renderSymptoms = () => {
    const components = [];

    for (const symptomType in symptoms) {
      if (!symptoms[symptomType].selected) {
        continue;
      }

      const symptomItemComponent = symptomItem(
        symptomType,
        symptoms[symptomType].fr_name,
        symptoms[symptomType].level,
        1,
        symptoms[symptomType].question,
        symptoms[symptomType].fr_question
      );

      if (symptomType === "pain") {
        components.push(
          <div key={`${symptomType}-pain`}>
            {symptomItemComponent}
            {painAreaBuilder()}
            {painAreasItem}
            <h5></h5>
          </div>
        );
      } else {
        components.push(symptomItemComponent);
      }
    }

    return components.length > 0 ? <>{components}</> : null;
  };

  // Prescribed meds
  const presMedsItem = prescribedMeds.length ? (
    prescribedMeds.map((med, i) => (
      <span key={med.id}>
        {(i ? ", " : "") + (lang === "English" ? med.name : med.fr_name)}
      </span>
    ))
  ) : (
    <p className="notFilled">
      {lang === "English" ? notProvidedEN : notProvidedFR}
    </p>
  );

  // Other treatments
  const otherTreatmentsItem = [...otherMeds, ...treatments].length ? (
    [...otherMeds, ...treatments].map((treat, i) => (
      <span key={treat.id}>
        {(i ? ", " : "") + (lang === "English" ? treat.name : treat.fr_name)}
      </span>
    ))
  ) : (
    <p className="notFilled">
      {lang === "English" ? notProvidedEN : notProvidedFR}
    </p>
  );

  // How often do you use prescribed arthritis medications
  const howOftenMedsItem =
    howOftenMeds != null ? (
      <p>{howOftenMeds}</p>
    ) : (
      <p className="notFilled">
        {lang === "English" ? notProvidedEN : notProvidedFR}
      </p>
    );

  // How often do you use other pain treatments
  const howOftenTreatsItem =
    howOftenTreats != null ? (
      <p>{howOftenTreats}</p>
    ) : (
      <p className="notFilled">
        {lang === "English" ? notProvidedEN : notProvidedFR}
      </p>
    );

  // How well do does your treatments control your arthritis
  const controlItem =
    controlArthritis != null ? (
      <p className="text-center">
        <div className="circle-radio-group-summary">
          <h5 style={{ color: "#B10921" }}>
            {lang === "English" ? "Not Well at All" : "Pas bien du tout"}{" "}
          </h5>
          <h5 style={{ color: "#4DAA33" }}>
            {lang === "English" ? "Very Well" : "Très bien"}
          </h5>
        </div>

        <div className="circle-radio-group-container">
          <div className="number-rating">{controlArthritis} / 5</div>
          <img
            className="circle-radio-group-img"
            src={require(`../../img/${controlArthritis}.png`)}
            alt="How much pain on average"
          />
        </div>
      </p>
    ) : (
      <p className="notFilled">
        {lang === "English" ? notProvidedEN : notProvidedFR}
      </p>
    );

  // How well do does your treatments manage your pain
  const manageItem =
    managePain != null ? (
      <p className="text-center">
        <div className="circle-radio-group-summary">
          <h5 style={{ color: "#B10921" }}>
            {lang === "English" ? "Not Well at All" : "Pas bien du tout"}{" "}
          </h5>
          <h5 style={{ color: "#4DAA33" }}>
            {lang === "English" ? "Very Well" : "Très bien"}
          </h5>
        </div>

        <div className="circle-radio-group-container">
          <div className="number-rating">{managePain} / 5</div>
          <img
            className="circle-radio-group-img"
            src={require(`../../img/${managePain}.png`)}
            alt="How much pain on average"
          />
        </div>
      </p>
    ) : (
      <p className="notFilled">
        {lang === "English" ? notProvidedEN : notProvidedFR}
      </p>
    );

  return (
    <div className="box-container-numbered summary-container">
      <p className="title_summary numbered-subheading">
        {lang === "English"
          ? "Step 1: Your symptoms and your treatments"
          : "Étape 1: Votre symptômes et vos traitements"}
      </p>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="padding-class">
          {renderSymptoms()}

          {/* Original Q3 */}
          <h5 className="subheader-summary">
            {" "}
            {lang === "English"
              ? `${++qNum}. What prescribed arthritis medication are you using?`
              : `${++qNum}. Quels médicaments prescrits pour l’arthrite prenez-vous?`}{" "}
          </h5>
          {presMedsItem}

          {/* Original Q4 */}
          <h5 className="subheader-summary">
            {lang === "English"
              ? `${++qNum}. What other treatments are you using when you have arthritis symptoms?`
              : `${++qNum}. Quels autres traitements utilisez-vous lorsque vous avez des symptômes?`}
          </h5>
          {otherTreatmentsItem}

          <h5 className="subheader-summary">
            {lang === "English"
              ? `${++qNum}. How often are you taking your prescribed arthritis medication?`
              : `${++qNum}. À quelle fréquence utilisez-vous vos médicaments sous ordonnance contre l’arthrite?`}
          </h5>
          {howOftenMedsItem}

          {/* Original Q6 */}
          <h5 className="subheader-summary">
            {lang === "English"
              ? `${++qNum}. How well do your treatments work?`
              : `${++qNum}. Dans quelle mesure vos traitements sont-ils efficaces?`}
          </h5>

          <h6 className="subtitle">
            {lang === "English"
              ? `How well does your treatment control your symptoms?`
              : `Dans quelle mesure votre traitement contrôle-t-il vos symptômes?`}
          </h6>
          <div className="summary-slider-container">{controlItem}</div>

          <h6 className="subtitle">
            {lang === "English"
              ? `How well does your treatment manage your symptoms?`
              : `Dans quelle mesure votre traitement gère-t-il vos symptômes?`}
          </h6>
          <div className="summary-slider-container"> {manageItem}</div>
        </div>
      )}
    </div>
  );
};

export default StepOne;
